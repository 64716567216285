<template>
  <div>
    <title>SIM INVENTORY ~ DATA ACCOUNT RECEIVER DETAIL</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Data Account Receiver Detail
        <br />
        <h4>The following is a list of data account receiver detail</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-save"></i>
                <h3 class="box-title">Save Account Receiver Details</h3>
              </div>
              <form role="form">
                <div class="box-body">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label for="exampleInputEmail1" class="col-md-2">COA Code</label>
                      <div class="col-md-10">
                        <input type="hidden" name="id" id="id" v-model="isidata.id">
                        <select2
                          :data="coalist"
                          :value="valuecoa"
                          @update="updatecoa($event)"
                        ></select2>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="exampleInputEmail1" class="col-md-2">Notes</label>
                      <div class="col-md-10">
                        <input
                          type="text"
                          v-model="isidata.catatdet"
                          autocomplete="off"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="exampleInputEmail1" class="col-md-2">Referensi</label>
                      <div class="col-md-10">
                        <select2
                          :data="referensilist"
                          :value="valuereferensi"
                          @update="updatereferensi($event)"
                        ></select2>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label for="exampleInputEmail1" class="col-md-2">Debet</label>
                      <div class="col-md-10">
                        <input
                          type="text"
                          v-model="isidata.deb"
                          autocomplete="off"
                          class="form-control"
                          placeholder="Debet"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="exampleInputEmail1" class="col-md-2">Credit</label>
                      <div class="col-md-10">
                        <input
                          type="text"
                          v-model="isidata.cre"
                          autocomplete="off"
                          class="form-control"
                          placeholder="Credit"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box-footer">
                  <button
                    @click="saveData()"
                    type="button"
                    class="btn btn-primary"
                  >
                    <i class="fa fa-save"></i>
                    Simpan
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">
                  Account Receiver Detail Data Lists
                  <b style="color: red">{{ nobuk }}</b>
                </h3>
              </div>
              <!-- <div class="box-body">
                <div class="row">
                  <div class="col-xs-5">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                </div>
              </div> -->
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <!-- <th>Number of Evidence</th> -->
                      <!-- <th>COA Code</th> -->
                      <th>Reference</th>
                      <th>Debet</th>
                      <th>Credit</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.ID"
                    >
                      <td>
                        {{ index + 1 + (page - 1) * pageSize }}
                      </td>
                      <!-- <td>{{ classdata.id }}</td> -->
                      <!-- <td>{{ classdata.no_bukti }}</td> -->
                      <!-- <td>{{ classdata.coa_code }}</td> -->
                      <td>{{ classdata.referensi }}</td>
                      <td>Rp. {{ formatPrice(classdata.debet) }}</td>
                      <td>Rp. {{ formatPrice(classdata.kredit) }}</td>
                      <td>{{ classdata.notes }}</td>
                      <td>
                        <button
                          title="Edit Account Receivable Detail"
                          @click="edit(classdata)"
                          class="btn btn-info"
                        >
                          <i class="fa fa-edit"></i>
                        </button>
                        &nbsp;
                        <button
                          title="Delete Account Receivable Detail"
                          @click="deleteData(classdata.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <!-- <td></td> -->
                      <!-- <td></td> -->
                      <!-- <td></td> -->
                      <td></td>
                      <td><b style="color:red"> Rp. {{formatPrice(totalDebet(list_paged))}} </b></td>
                      <td><b style="color:teal"> Rp. {{formatPrice(totalKredit(list_paged))}} </b></td>
                      <td></td>
                      <!-- <td></td> -->
                    </tr>
                  </tfoot>
                </table>
                <!-- <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                /> -->
              </div>
            </div>

            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <th>No.</th>
                    <!-- <th>Idx</th> -->
                    <!-- <th>Number of Evidence</th> -->
                    <!-- <th>COA Code</th> -->
                    <th>Reference</th>
                    <th>Debet</th>
                    <th>Credit</th>
                    <th>Notes</th>
                    <!-- <th>Action</th> -->
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="5" class="text-center">
                          <b style="color: red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
// import VPagination from "@hennge/vue3-pagination";
// import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "chbankind",
 /*  components: {
    VPagination,
  }, */
  data() {
    return {
      isidata: {
        id:"",
        nomorbukti: "",
        kodecoa: "",
        catatdet: "",
        refen: "",
        deb: "",
        cre: "",
      },
      isShow: false,
      isExist: true,
      loading: false,
      namesearch: "",
      valuecoa: "",
      valuereferensi: "",
      nobuk: "",
      list_paged: [],
      coalist: [],
      referensilist: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500],
    };
  },
  created() {
    this.nobuk = this.$route.params.nobukti;
    this.fetchData();
    this.listcoa()
    this.listreferensi()
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchData();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.fetchData();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    totalDebet(values) {
      return values.reduce((acc, val) => {
        return acc + parseInt(val.debet);
      }, 0);
    },
    totalKredit(values) {
      return values.reduce((acc, val) => {
        return acc + parseInt(val.kredit);
      }, 0);
    },
    updatecoa(value) {
      this.valuecoa = value;
    },
    updatereferensi(value) {
      this.valuereferensi = value;
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getalldatabarang?length="+this.pageSize+"&page="+this.page;
      const urlAPIget = this.$apiurl + "po_header/getalldatapo_header_bukan_close?page=1&length=20000&cari="+this.valuereferensi;
      // console.log(urlAPIget);
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.isidata.deb = resp.data.data[0].total
          this.loading = false
        })
        .catch((err) => {
          console.log(err.reponse.data);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    listreferensi(){
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getalldatabarang?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl +
        "po_header/getalldatapo_header_bukan_close?page=1&length=20000";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          this.referensilist = resp.data.data;
          this.referensilist.forEach((item) => {
            item.value = item.po_number;
            item.label = item.po_number;
          });
        })
        .catch((err) => {
          console.log(err.reponse.data);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    listcoa() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getalldatabarang?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl +
        "acc_ap_d/getalldatacoa_cash_bank?page=1&length=5000";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          this.coalist = resp.data.data;
          this.coalist.forEach((item) => {
            item.value = item.coa_code;
            item.label = item.coa_name;
          });
        })
        .catch((err) => {
          console.log(err.reponse.data);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    async saveData() {
      this.loading = true;
      var kdcoa = this.valuecoa;
      var cat = this.isidata.catatdet;
      var refe = this.valuereferensi;
      var cd = this.isidata.cre;
      var db = this.isidata.deb;
      if (kdcoa == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "COA Code can not be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else if (cat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else if (refe == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Reference can not be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else if (cd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Credit can not be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else if (db == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Debet can not be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else {
        var kodeuser = sessionStorage.getItem("kodeuser");
        const paramdatad = {
          no_bukti: this.$route.params.nobukti,
          coa_code: kdcoa,
          notes: cat,
          kode_user: kodeuser,
          referensi: refe,
          debet: db,
          kredit: cd,
        };

        const tokenlogin = sessionStorage.getItem("token");
        let urlAPISaveDatad = "";
        let ax = axios;
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin,
        };
        if(this.isidata.id!=""){
          urlAPISaveDatad = this.$apiurl + "acc_ar_d/updateAcc_ar_d/"+this.isidata.id; 
          ax = axios.put(urlAPISaveDatad, paramdatad, { headers })
        } else {
          urlAPISaveDatad=this.$apiurl + "acc_ar_d/saveacc_ar_d";
          ax = axios.post(urlAPISaveDatad, paramdatad, { headers })
        }
        ax
          .then((respn) => {
            if (respn.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false,
              });
              this.valuecoa = "";
              this.isidata.id ="";
              this.isidata.catatdet = "";
              this.valuereferensi = "";
              this.isidata.deb = "";
              this.isidata.cre = "";
              this.loading = false;
              this.fetchData();
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false,
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false,
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    searchdata() {
      this.loading = true;
      // const params = this.getRequestParams(this.page);
      const params = this.getRequestParams(this.pagestat);
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/jurnal_umum_h/getalldatajurnal_umum_h?cari=" + this.namesearch+"&length="+this.pageSize
      const urlAPIget =
        this.$apiurl +
        "acc_ar_d/getalldataacc_ar_d?cari=" +
        this.namesearch +
        "&length=" +
        this.pageSize +
        "&no_bukti=" +
        this.$route.params.nobukti;
      axios
        .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
        .then((resp) => {
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/jurnal_umum_h/getalldatajurnal_umum_h?length="+this.pageSize+"&page="+this.page
      const urlAPIget =
        this.$apiurl +
        "acc_ar_d/getalldataacc_ar_d?length=" +
        this.pageSize +
        "&page=" +
        this.page +
        "&no_bukti=" +
        this.$route.params.nobukti;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          console.log(resp);
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          // const urlAPIbrgdel = "http://26.183.23.191/inventory/backend/inventory/api/jurnal_umum_h/hapusjurnal_umum_h/" + id;
          const urlAPIbrgdel =
            this.$apiurl + "acc_ap_d/hapusacc_ap_d/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin,
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully",
                }).then(function () {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed",
                }).then(function () {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data,
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
    edit(data){
      this.isidata.id=data.id;
      this.isidata.nomorbukti=data.no_bukti;
      this.valuecoa=data.coa_code;
      this.isidata.catatdet=data.notes;
      this.valuereferensi=data.referensi;
      this.isidata.deb=data.debet;
      this.isidata.cre=data.kredit;
    }
  },
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
